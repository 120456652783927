import React, { useMemo } from 'react';
import { FlexBox } from 'src/ui/common/atoms/flex-box';
import { MarkRequired } from 'src/ui/common/atoms/mark-required';
import { Spacing } from 'src/ui/common/atoms/spacing';
import { Typography } from 'src/ui/common/atoms/typography';
import { IFormTextInput } from 'src/ui/common/molecules/form-text-input/form-text-input.types';
import { v4 as uuid } from 'uuid';
import { useGetUniverseTemplate } from 'src/hooks/use-get-universe-template';
import { SvgIcon } from '../../atoms/svg-icon';
import TooltipComponent from '../../organisms/tooltip/tooltip.component';
import {
    StyledDescription,
    StyledHelpMessage,
    StyledInputWrapper,
    StyledLabel,
    StyledTextInput,
    StyledWrapper,
    TooltipWrapper,
} from './form-text-input.styles';

export const FormTextInput = React.forwardRef(
    (
        {
            state,
            label,
            subTitleLabel,
            maxLength,
            testId,
            error,
            message,
            children,
            isRequired,
            tooltipMessage,
            ...rest
        }: IFormTextInput,
        ref: React.ForwardedRef<HTMLInputElement>
    ) => {
        const inputId = useMemo(() => rest?.id ?? uuid(), [rest?.id]);
        const { isTechContemporary } = useGetUniverseTemplate();

        return (
            <div data-test-id={testId}>
                {label && !tooltipMessage && (
                    <StyledLabel isTechContemporary={isTechContemporary}>
                        <FlexBox>
                            <Spacing mr="5px">
                                {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                                <label htmlFor={inputId}>{label}</label>
                                <Spacing mt="5px">
                                    <Typography size="xs" color="gs200">
                                        {subTitleLabel}
                                    </Typography>
                                </Spacing>
                            </Spacing>
                            {isRequired && <MarkRequired mt="-5px" />}
                        </FlexBox>
                    </StyledLabel>
                )}
                {label && tooltipMessage && (
                    <StyledWrapper>
                        <StyledLabel>
                            <FlexBox>
                                <Spacing mr="5px">
                                    {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                                    <label htmlFor={inputId}>{label}</label>
                                </Spacing>
                                {isRequired && <MarkRequired mt="-5px" />}
                            </FlexBox>
                        </StyledLabel>
                        <TooltipWrapper>
                            <TooltipComponent
                                content={
                                    <StyledDescription size="xs" color="warmBlack">
                                        {tooltipMessage}
                                    </StyledDescription>
                                }
                                position="bottom"
                                isFloating={false}
                            >
                                <SvgIcon variant="bold" icon="information" width={17} height={17} />
                            </TooltipComponent>
                        </TooltipWrapper>
                    </StyledWrapper>
                )}
                <StyledInputWrapper>
                    <StyledTextInput
                        ref={ref}
                        maxLength={maxLength}
                        id={inputId}
                        state={error ? 'error' : undefined}
                        {...rest}
                    />
                    {children}
                </StyledInputWrapper>
                {message && <StyledHelpMessage state={state}>{message}</StyledHelpMessage>}
                {error && <StyledHelpMessage state="error">{error}</StyledHelpMessage>}
            </div>
        );
    }
);

FormTextInput.displayName = 'FormTextInput';