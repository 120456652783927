import React from 'react';
import { Spacing } from '../spacing';
import { Typography } from '../typography';
import { ISpacing } from '../spacing/spacing.types';

export const MarkRequired = ({ ...props }: ISpacing) => (
    <Spacing {...props}>
        <Typography color='pink500'>*</Typography>
    </Spacing>
);
