/**
 * Libs
 */
import { TUserIdentity, UserAPI } from '@tacans/shared-marketplace-layer';
import { Trans, useTranslation } from 'next-i18next';
import getConfig from 'next/config';
import React, { useCallback, useState } from 'react';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
    StyledAuthFormContainer,
    StyledAuthFormTitle,
    StyledAuthTrigger,
    StyledFormError,
} from 'src/features/common/user/modals/auth-modal/auth-modal.styles';
import { AuthModalTypeT } from 'src/features/common/user/modals/auth-modal/auth-modal.types';
import { useScreenDetect } from 'src/hooks/useScreenDetect';

/**
 * Types
 */
import { ModalLocaleI } from 'src/localisation/localisation.types';
import { Typography } from 'src/ui/common/atoms/typography';

/**
 * Components
 */
import { FormTextInput } from 'src/ui/common/molecules/form-text-input';
import { Button } from 'src/ui/white-labels/phoenxHolding/atoms/button';

/**
 * Hooks
 */
import { useValidation } from 'src/utils/validation.utils';

export interface ResetPasswordFormValuesI {
    email: string;
}

const requestResetPassword = async (payload: TUserIdentity) => {
    const response = await UserAPI.resetPassword(payload);

    // TODO: build reusable wrapper or axios middleware
    const errorMessage = response.data.responseMeta.error?.messages?.[0];

    if (errorMessage) {
        throw new Error(errorMessage);
    }

    return response;
};

const componentTestId = 'reset-password-modal';

interface IResetPasswordFormLocale extends ModalLocaleI {
    form: {
        email: string;
        submit: string;
    };
    success: string;
}

interface IProps {
    onSwitch: (tab: AuthModalTypeT) => void;
}

const ResetPasswordForm: React.VFC<IProps> = ({ onSwitch }) => {
    const screen = useScreenDetect();
    const { t } = useTranslation(['common', 'modals', 'form', 'actions']);
    const locale: IResetPasswordFormLocale = {
        ...t('auth.reset', { ns: 'modals', returnObjects: true }),
        form: {
            email: t('auth.reset.email', { ns: 'modals' }),
            submit: t('auth.reset.submit', { ns: 'modals' }),
        },
        success: t('auth.reset.success', { ns: 'modals' }),
    };

    const { executeRecaptcha } = useGoogleReCaptcha();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<ResetPasswordFormValuesI>();
    const { isRequired } = useValidation();

    const [error, setError] = useState<string>();
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const onSubmit: SubmitHandler<ResetPasswordFormValuesI> = useCallback(
        async (data) => {
            setError(undefined);

            const captcha = await executeRecaptcha?.();

            if (captcha) {
                try {
                    setLoading(true);
                    await requestResetPassword(data);
                    setSuccess(true);
                } catch (error: any) {
                    setError(error.message);
                } finally {
                    setLoading(false);
                }
            }
        },
        [executeRecaptcha]
    );

    return (
        <form onSubmit={handleSubmit(onSubmit)} data-test-id={`${componentTestId}-form`}>
            <StyledAuthFormContainer flexDirection="column">
                <StyledAuthFormTitle>
                    <Typography variant="title" size="lg" tag="h2" color="text" medium>
                        {locale.title}
                    </Typography>
                    {!success && (
                        <Typography variant="text" size="xs" tag="span">
                            {locale.subtitle}
                        </Typography>
                    )}
                </StyledAuthFormTitle>
                {!success ? (
                    <>
                        <FormTextInput
                            error={errors.email?.message}
                            placeholder={locale.form.email}
                            type="email"
                            data-test-id={`${componentTestId}-input-login-or-email`}
                            {...register('email', { ...isRequired() })}
                            autoFocus
                        />
                        {error && (
                            <StyledFormError variant="text" size="sm" color="pink500" tag="div">
                                {error}
                            </StyledFormError>
                        )}
                        <Button type="submit" size={screen.xs || screen.sm ? 'md' : 'lg'} disabled={loading} stretch>
                            {locale.form.submit}
                        </Button>
                    </>
                ) : (
                    <Typography variant="text" size="sm" tag="p">
                        Check your inbox for a link to reset your password
                    </Typography>
                )}
                <Typography variant="text" size="xs">
                    <Trans t={t} i18nKey="auth.reset.back" ns="modals">
                        <StyledAuthTrigger onClick={() => onSwitch('Login')} />
                    </Trans>
                </Typography>
            </StyledAuthFormContainer>
        </form>
    );
};

const ResetPasswordModal: React.VFC<IProps> = ({ onSwitch }) => {
    const { publicRuntimeConfig } = getConfig();

    return (
        <GoogleReCaptchaProvider reCaptchaKey={publicRuntimeConfig.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}>
            <ResetPasswordForm onSwitch={onSwitch} />
        </GoogleReCaptchaProvider>
    );
};

export { ResetPasswordModal };