/**
 * Libs
 */
import React, { useCallback } from 'react';
import { FloatingContext } from '@floating-ui/react-dom-interactions';

export const useCursorFollow = (context: FloatingContext, options: { enabled: boolean }) => {
    const { onOpenChange, reference } = context;

    const onMouseEnter = useCallback(() => {
        onOpenChange(true);
    }, [onOpenChange]);

    const onMouseLeave = useCallback(() => {
        onOpenChange(false);
    }, [onOpenChange]);

    const onMouseMove = useCallback((event: React.MouseEvent) => {
        const x = event.clientX;
        const y = event.clientY;

        reference({
            getBoundingClientRect: () => ({
                width: 0,
                height: 0,
                left: x,
                top: y,
                right: x,
                bottom: y,
                x,
                y
            })
        });
    }, [reference]);

    if (!options.enabled) return {};

    return {
        reference: {
            onMouseMove,
            onMouseEnter,
            onMouseLeave
        }
    };
};
