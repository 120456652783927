/**
 * Libs
 */
import dynamic from 'next/dynamic';

/**
 * Features
 */
import { modalRegistry } from 'src/features/common/modal/providers/modal.registry';

const AuthWelcomeModalComponent = dynamic(
    () => import('./auth-welcome-modal-component').then((d) => d.AuthWelcomeModalComponent as any),
    {
        ssr: false,
    }
);

export const AuthWelcomeModal = modalRegistry.register({
    id: 'Auth Welcome',
    component: AuthWelcomeModalComponent,
});
