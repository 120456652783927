/**
 * Libs
 */
import { ResponseMetaStatusE, UserAPI } from '@tacans/shared-marketplace-layer';
import { Trans, useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { constraints } from 'src/constants/constraints.constants';
import { useProfileClear } from 'src/features/common/profile/hooks/profile.hooks';
import { useUniverse } from 'src/features/common/universe/hooks/use-universe';
import { usePasswordRequirementsColor } from 'src/features/common/user/hooks/use-password-requirements-color';

/**
 * Components
 */
import { AuthWelcomeModal } from 'src/features/common/user/modals/auth-welcome-modal';
import { useHandleSubmissionError } from 'src/hooks/form.hooks';
import { useScreenDetect } from 'src/hooks/useScreenDetect';

/**
 * Types
 */
import { ModalLocaleI } from 'src/localisation/localisation.types';
import { Button } from 'src/ui/common/atoms/button';
import { FlexBox } from 'src/ui/common/atoms/flex-box';
import { Typography } from 'src/ui/common/atoms/typography';
import { FormTextInput } from 'src/ui/common/molecules/form-text-input';
import { PasswordInput } from 'src/ui/common/molecules/password-input';

/**
 * Hooks
 */
import { useValidation } from 'src/utils/validation.utils';
import { useTheme } from 'styled-components';

/**
 * Styles
 */
import {
    StyledAuthFieldsContainer,
    StyledAuthFormContainer,
    StyledAuthFormTitle,
    StyledAuthTrigger,
    StyledPasswordRequirements,
    StyledValidationIcon,
} from '../auth-modal.styles';
import { AuthModalTypeT } from '../auth-modal.types';

export interface CreateUserFormValues {
    login: string;
    email: string;
    password: string;
}

export const createUserFormDefaultValues: CreateUserFormValues = {
    login: '',
    email: '',
    password: '',
};

interface IRegistrationModalLocale extends ModalLocaleI {
    form: {
        login: string;
        email: string;
        password: string;
        submit: string;
    };
    requirements: {
        minChars: string;
        reqNumber: string;
        reqLower: string;
        reqUpper: string;
    };
}

interface IProps {
    onSwitch: (tab: AuthModalTypeT) => void;
}

const componentTestId = 'registration-form';

const RegistrationModal = ({ onSwitch }: IProps) => {
    const router = useRouter();
    const screen = useScreenDetect();
    const { t } = useTranslation(['modals', 'form', 'actions']);
    const { projectName } = useUniverse();
    const theme = useTheme();
    const [isLoading, setIsLoading] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
        clearErrors,
        setError,
        watch,
    } = useForm<CreateUserFormValues>({
        defaultValues: createUserFormDefaultValues,
    });
    const { isRequired, validatePassword } = useValidation();

    const locale: IRegistrationModalLocale = useMemo(
        () => ({
            ...t('auth.register', { ns: 'modals', returnObjects: true, projectName }),
            form: {
                login: t('auth.register.login'),
                email: t('auth.register.email'),
                password: t('auth.register.password'),
                submit: t('auth.register.submit'),
            },
            requirements: {
                minChars: t('auth.register.minChars', { minChars: constraints.user.password.minLength }),
                reqNumber: t('auth.register.reqNumber'),
                reqLower: t('auth.register.reqLower'),
                reqUpper: t('auth.register.reqUpper'),
            },
        }),
        [t, projectName]
    );

    const { password } = watch();

    const requirements = usePasswordRequirementsColor(password);

    const { handleError, validationErrors, responseError, resetValidationErrors, resetResponseError } =
        useHandleSubmissionError<CreateUserFormValues>(
            Object.keys(createUserFormDefaultValues) as (keyof CreateUserFormValues)[]
        );

    useEffect(() => {
        clearErrors();

        if (!validationErrors?.length) {
            return;
        }

        validationErrors.forEach(([field, message]) =>
            setError(field, {
                message,
                type: 'validate',
            })
        );
    }, [clearErrors, setError, validationErrors]);

    const clearProfile = useProfileClear();

    const handleCloseModal = () => router.back();

    const mutate = useCallback(
        (data) => {
            setIsLoading(true);

            UserAPI.signupUser({
                ...data,
                password: data.password.trim(),
            })
                .then((response) => {
                    const { status, error } = response.data.responseMeta;

                    if (status === ResponseMetaStatusE.OK) {
                        AuthWelcomeModal.open({ preventEasyClose: true });
                        return;
                    }
                    error && handleError(error.messages);
                })
                .catch((error) => {
                    handleError(error);
                    clearProfile();
                })
                .finally(() => {
                    setIsLoading(false);
                });
        },
        [clearProfile, handleError]
    );

    const handleOnChange = useCallback(() => {
        if (responseError) {
            resetResponseError();
        }
    }, [responseError, resetResponseError]);

    const onSubmit: SubmitHandler<CreateUserFormValues> = useCallback(
        (data) => {
            if (responseError) {
                return undefined;
            }

            resetValidationErrors();

            return mutate(data);
        },
        [responseError, resetValidationErrors, mutate]
    );

    return (
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <StyledAuthFormContainer flexDirection="column">
                <StyledAuthFormTitle>
                    <Typography variant="title" size="lg" tag="h2" color="text" medium>
                        {locale.title}
                    </Typography>
                    <Typography variant="text" size="xs" tag="span">
                        {locale.subtitle}
                    </Typography>
                </StyledAuthFormTitle>
                <StyledAuthFieldsContainer flexDirection="column">
                    <FormTextInput
                        error={errors.login?.message}
                        placeholder={locale.form.login}
                        {...register('login', {
                            ...isRequired(),
                            onChange: handleOnChange,
                        })}
                    />
                    <FormTextInput
                        error={errors.email?.message}
                        placeholder={locale.form.email}
                        type="email"
                        {...register('email', {
                            ...isRequired(),
                            onChange: handleOnChange,
                        })}
                    />
                    <PasswordInput
                        placeholder={locale.form.password}
                        error={errors.password?.message}
                        {...register('password', {
                            ...isRequired(),
                            ...validatePassword(),
                            onChange: handleOnChange,
                        })}
                    />
                </StyledAuthFieldsContainer>
                <StyledPasswordRequirements xs={2} columnGap={6} rowGap={11}>
                    <Typography tag="div" variant="text" size="xxs">
                        <FlexBox alignItems="center">
                            <StyledValidationIcon
                                variant="regular"
                                icon={requirements.min ? 'check' : 'cross'}
                                color={requirements.min ? theme.colors.green500 : theme.colors.red500}
                            />
                            {locale.requirements.minChars}
                        </FlexBox>
                    </Typography>
                    <Typography tag="div" variant="text" size="xxs">
                        <FlexBox alignItems="center">
                            <StyledValidationIcon
                                variant="regular"
                                icon={requirements.number ? 'check' : 'cross'}
                                color={requirements.number ? theme.colors.green500 : theme.colors.red500}
                            />
                            {locale.requirements.reqNumber}
                        </FlexBox>
                    </Typography>
                    <Typography tag="div" variant="text" size="xxs">
                        <FlexBox alignItems="center">
                            <StyledValidationIcon
                                variant="regular"
                                icon={requirements.lower ? 'check' : 'cross'}
                                color={requirements.lower ? theme.colors.green500 : theme.colors.red500}
                            />
                            {locale.requirements.reqLower}
                        </FlexBox>
                    </Typography>
                    <Typography tag="div" variant="text" size="xxs">
                        <FlexBox alignItems="center">
                            <StyledValidationIcon
                                variant="regular"
                                icon={requirements.upper ? 'check' : 'cross'}
                                color={requirements.upper ? theme.colors.green500 : theme.colors.red500}
                            />
                            {locale.requirements.reqUpper}
                        </FlexBox>
                    </Typography>
                </StyledPasswordRequirements>
                <Button
                    type="submit"
                    disabled={isLoading || Boolean(responseError)}
                    data-test-id={`${componentTestId}-btn-${isLoading ? 'submit' : 'register'}`}
                    size={screen.xs || screen.sm ? 'md' : 'lg'}
                    stretch
                >
                    {locale.form.submit}
                </Button>
                <Typography variant="text" size="xs">
                    <Trans t={t} i18nKey="auth.register.signIn">
                        <StyledAuthTrigger onClick={() => onSwitch('Login')} />
                    </Trans>
                </Typography>
            </StyledAuthFormContainer>
        </form>
    );
};

export { RegistrationModal };