export const authPreviewArtists = [
    {
        name: 'hoegenhaug',
        url: 'https://spaceseven.com/profile/hoegenhaug',
        preview: 'hoegenhaug.webp',
    },
    {
        name: 'ico_sphere',
        url: 'https://spaceseven.com/profile/ico_sphere',
        preview: 'ico_sphere.webp',
    },
    {
        name: 'WhiteHorse',
        url: 'https://spaceseven.com/profile/WhiteHorse',
        preview: 'whitehorse.webp',
    },
    {
        name: 'Grafito.Visuals',
        url: 'https://spaceseven.com/profile/grafito.visuals',
        preview: 'grafito-visuals.webp',
    },
    {
        name: 'Teddy Mascot Club',
        url: 'https://spaceseven.com/profile/ExclusiveTeddyMascotClub',
        preview: 'teddy.webp',
    },
    {
        name: 'buvra',
        url: 'https://spaceseven.com/profile/buvra',
        preview: 'burva.webp',
    },
];
