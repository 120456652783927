import React, { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { RouteE } from 'src/constants/pages.constants';
import { useUniverse } from 'src/features/common/universe/hooks/use-universe';
import { authPreviewArtists } from 'src/features/common/user/modals/auth-modal/auth-modal.constants';
import {
    StyledAuthContent,
    StyledAuthHeader,
    StyledPreviewContainer,
} from 'src/features/common/user/modals/auth-modal/auth-modal.styles';
import { AuthModalProps } from 'src/features/common/user/modals/auth-modal/auth-modal.types';
import { LoginModal } from 'src/features/white-labels/phoenxholding/user/modals/auth-modal/templates/login-modal';
import { RegistrationModal } from 'src/features/white-labels/phoenxholding/user/modals/auth-modal/templates/registration-modal';
import { ResetPasswordModal } from 'src/features/white-labels/phoenxholding/user/modals/auth-modal/templates/reset-password-modal';
import { FlexBox } from 'src/ui/common/atoms/flex-box';
import { TextLink } from 'src/ui/common/atoms/text-link';
import { Typography } from 'src/ui/common/atoms/typography';
import { Modal } from 'src/ui/common/layouts/modal';
import { LazyLoadMedia } from 'src/ui/common/organisms/lazy-load-media';
import { getRandomInt } from 'src/utils/helpers.utils';

const componentTestId = 'auth-modal';

export const AuthModalComponent = ({ type: typeInitial }: AuthModalProps) => {
    const { t } = useTranslation(['modals']);
    const { projectName } = useUniverse();
    const [type, setType] = useState(typeInitial);

    const tokenPreview = useMemo(() => {
        const id = getRandomInt(0, authPreviewArtists.length - 1);
        const current = authPreviewArtists[id];

        return (
            <>
                <LazyLoadMedia src={`/token-previews/${current.preview}`} />
                <Typography tag="p" variant="text" size="xs">
                    <Trans t={t} i18nKey="auth.art" ns="modals" values={{ artistName: current.name }}>
                        {/* eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/control-has-associated-label */}
                        <a href={current.url} target="_blank" rel="noreferrer" />
                    </Trans>
                </Typography>
            </>
        );
    }, [t]);

    return (
        <Modal testId={componentTestId} fullScreen>
            <StyledAuthContent flexDirection="column">
                <StyledAuthHeader justifyContent="space-between">
                    <img src="/logo.svg" alt="" />
                </StyledAuthHeader>
                <StyledPreviewContainer view={['desktop', 'laptop']}>{tokenPreview}</StyledPreviewContainer>
                <FlexBox flexDirection="column" flex="1">
                    <FlexBox flexDirection="column">
                        {type === 'Login' && <LoginModal onSwitch={setType} />}
                        {type === 'Registration' && <RegistrationModal onSwitch={setType} />}
                        {type === 'Reset Password' && <ResetPasswordModal onSwitch={setType} />}
                    </FlexBox>
                    <Typography tag="p" variant="text" size="xxs" color="gs300">
                        <Trans
                            t={t}
                            i18nKey="auth.legal"
                            values={{ projectName }}
                            components={{
                                linkOne: <TextLink href={RouteE.TERMS} />,
                                linkTwo: <TextLink href={RouteE.PRIVACY} />,
                            }}
                        />
                    </Typography>
                </FlexBox>
            </StyledAuthContent>
        </Modal>
    );
};