/**
 * Libs
 */
import {
    arrow,
    autoUpdate,
    flip,
    FloatingPortal,
    offset,
    safePolygon,
    shift,
    useFloating,
    useHover,
    useInteractions,
} from '@floating-ui/react-dom-interactions';
import React, { useMemo, useRef, useState } from 'react';

/**
 * Constants
 */
import { TOOLTIP_ARROW_SIZE } from 'src/ui/common/organisms/tooltip/tooltip.constants';

/**
 * Hooks
 */
import { useCursorFollow } from 'src/ui/common/organisms/tooltip/tooltip.hooks';

/**
 * Types
 */
import { TooltipProperties } from 'src/ui/common/organisms/tooltip/tooltip.types';

/**
 * Styles
 */
import { StyledTooltip, StyledTooltipArrow } from './tooltip.component.styles';

const TooltipComponent: React.FC<TooltipProperties> = ({
    children,
    disabled,
    content,
    position = 'top',
    isFloating,
    hasTooltipInteraction,
    themeColor = 'dark',
}) => {
    const [open, setOpen] = useState(false);
    const arrowRef = useRef<HTMLDivElement>(null);
    const {
        x,
        y,
        reference,
        placement,
        context,
        floating,
        strategy,
        middlewareData: { arrow: { x: arrowX, y: arrowY } = {} },
    } = useFloating({
        placement: position,
        strategy: 'fixed',
        open,
        onOpenChange: setOpen,
        middleware: [
            shift(),
            flip(),
            offset(8),
            arrow({
                element: arrowRef,
            }),
        ],
        whileElementsMounted: autoUpdate,
    });

    const { getReferenceProps } = useInteractions([
        useHover(context, {
            handleClose: hasTooltipInteraction ? safePolygon({ restMs: 50 }) : null,
        }),
        useCursorFollow(context, { enabled: isFloating ?? false }),
    ]);

    const staticSide = useMemo(
        () =>
            ({
                top: 'top',
                left: 'left',
                bottom: 'bottom',
                right: 'right',
            }[placement.split('-')[0]]),
        [placement]
    );

    if (disabled) {
        return children;
    }

    return (
        <>
            {React.cloneElement(children, getReferenceProps({ ref: reference, ...children.props }))}
            <FloatingPortal>
                {open && (
                    <>
                        <StyledTooltip
                            ref={floating}
                            style={{
                                position: strategy,
                                top: y ?? '',
                                left: x ?? '',
                            }}
                            themeColor={themeColor}
                        >
                            {content}
                            {!isFloating && (
                                <StyledTooltipArrow
                                    ref={arrowRef}
                                    style={{
                                        left: arrowX,
                                        top: arrowY,
                                        [staticSide as string]: `calc(100% - ${TOOLTIP_ARROW_SIZE / 2}px)`,
                                    }}
                                    themeColor={themeColor}
                                />
                            )}
                        </StyledTooltip>
                    </>
                )}
            </FloatingPortal>
        </>
    );
};

TooltipComponent.displayName = 'Tooltip';

export default TooltipComponent;