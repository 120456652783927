import React, { ComponentType, FC } from 'react';
import { useGetUniverseTemplate } from 'src/hooks/use-get-universe-template';
import TechCustom404 from '../features/white-labels/techcontemporary/404/index';

function withUniverseVerification<T extends any>(Component: ComponentType<T>): FC<T> {
    const ComponentWithGuard: FC<T> = (props) => {
        const { isTechContemporary } = useGetUniverseTemplate();
        // @ts-ignore
        return isTechContemporary ? <TechCustom404 /> : <Component {...props} />;
    };
    ComponentWithGuard.displayName = Component.displayName || Component.name || 'withUniverseVerification';
    return ComponentWithGuard;
}

export default withUniverseVerification;