/**
 * Libs
 */

/**
 * Constants
 */
import { TOOLTIP_ARROW_SIZE } from 'src/ui/common/organisms/tooltip/tooltip.constants';
import styled from 'styled-components';


export const StyledTooltip = styled.div<{ themeColor?: string }>`
    z-index: ${({ theme }) => theme.constants.maxZIndex};
    pointer-events: none;
    padding: 10px;
    color: ${({ theme, themeColor }) => (themeColor === 'light' ? theme.colors.white : theme.colors.warmBlack)};
    background-color: ${({ theme, themeColor }) => (themeColor === 'light' ? theme.colors.gs600 : theme.colors.white)};
    border-radius: 5px;
    width: fit-content;
    white-space: nowrap;
    word-break: break-word;
`;

export const StyledTooltipArrow = styled.div<{ themeColor?: string }>`
    z-index: ${({ theme }) => theme.constants.maxZIndex};
    position: absolute;
    width: ${TOOLTIP_ARROW_SIZE}px;
    height: ${TOOLTIP_ARROW_SIZE}px;
    transform: rotate(45deg);
    background-color: ${({ theme, themeColor }) =>
        themeColor === 'light' ? theme.colors.gs600 : theme.colors.white}; ;
`;