import { GetServerSideProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import React from 'react';
import withUniverseVerification from 'src/HOCs/withUniverseVerification';
import { PAGES_LOCALES } from 'src/constants/common.constants';
import { AuthModalComponent } from 'src/features/common/user/modals/auth-modal/auth-modal-component';
import { AuthModalComponent as PHAuthModalComponent } from 'src/features/white-labels/phoenxholding/user/modals/auth-modal/auth-modal-component';
import { AuthModalComponent as SAuthModalComponent } from 'src/features/white-labels/syndicate/user/modals/auth-modal/auth-modal-component';
import { AuthModalComponent as SMAuthModalComponent } from 'src/features/white-labels/thesportmuseum/user/modals/auth-modal/auth-modal-component';
import { useGetUniverseTemplate } from 'src/hooks/use-get-universe-template';
import { wrapper } from 'src/store';
import { PageLayout } from 'src/ui/common/layouts/page-layout';

const AuthPage = () => {
    const { isSyndicate, isPhoenxholding, isSportsMuseumTemplate } = useGetUniverseTemplate();

    const getAuthModalComponent = () => {
        if (isSyndicate) {
            return <SAuthModalComponent type="Login" />;
        }
        if (isPhoenxholding) {
            return <PHAuthModalComponent type="Login" />;
        }
        if (isSportsMuseumTemplate) {
            return <SMAuthModalComponent type="Login" />;
        }
        return <AuthModalComponent type="Login" />;
    };

    return <PageLayout fullWidth>{getAuthModalComponent()}</PageLayout>;
};

export const getServerSideProps: GetServerSideProps = wrapper.getServerSideProps(() => async (ctx) => ({
    props: {
        ...(await serverSideTranslations(ctx.locale as string, PAGES_LOCALES)),
    },
}));

export default withUniverseVerification(AuthPage);