/**
 * Libs
 */
import { UserIdentity, UserLoginRequest } from '@tacans/shared-marketplace-layer';
import { Trans, useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { RouteE } from 'src/constants/pages.constants';
import { useRunActionTriggersAfterLogin } from 'src/features/common/action-triggers/hooks/use-run-action-triggers-after-login';
import { useUniverse } from 'src/features/common/universe/hooks/use-universe';

/**
 * Hooks
 */
import {
    useClearUserError,
    useUserError,
    useUserLoggedIn,
    useUserLoggingIn,
    useUserLogin,
} from 'src/features/common/user/hooks/user.hooks';
import { useScreenDetect } from 'src/hooks/useScreenDetect';

/**
 * Types
 */
import { ModalLocaleI } from 'src/localisation/localisation.types';
import { Button } from 'src/ui/common/atoms/button';
import { Typography } from 'src/ui/common/atoms/typography';

/**
 * Components
 */
import { FormTextInput } from 'src/ui/common/molecules/form-text-input';
import { PasswordInput } from 'src/ui/common/molecules/password-input';
import { useValidation } from 'src/utils/validation.utils';

/**
 * Styles
 */
import {
    StyledAuthFieldsContainer,
    StyledAuthFormContainer,
    StyledAuthFormTitle,
    StyledAuthTrigger,
    StyledFormError,
} from '../auth-modal.styles';
import { AuthModalTypeT } from '../auth-modal.types';

interface ILoginModalLocale extends ModalLocaleI {
    form: {
        login: string;
        password: string;
        submit: string;
    };
    forgotUsername: string;
}

interface IProps {
    onSwitch: (tab: AuthModalTypeT) => void;
}

const componentTestId = 'auth-form';

const LoginModal = ({ onSwitch }: IProps) => {
    const router = useRouter();
    const screen = useScreenDetect();
    const { t } = useTranslation(['modals', 'form', 'actions']);
    const { projectName } = useUniverse();
    const locale: ILoginModalLocale = useMemo(
        () => ({
            ...t('auth.login', { ns: 'modals', returnObjects: true, projectName }),
            form: {
                login: t('auth.login.login'),
                password: t('auth.login.password'),
                submit: t('auth.login.submit'),
            },
            forgotUsername: t('auth.login.forgotUsername'),
        }),
        [t, projectName]
    );

    const defaultValues: UserLoginRequest = {
        identity: {
            login: '',
        },
        password: '',
    };

    const {
        register,
        handleSubmit,
        formState: { errors, isDirty: isFormDirty },
        setValue,
    } = useForm({ defaultValues });
    const { isRequired } = useValidation();
    const userLogin = useUserLogin();
    const userLogginIn = useUserLoggingIn();
    const userError = useUserError();
    const clearError = useClearUserError();
    const userLoggedIn = useUserLoggedIn();
    const runActionTriggersAfterLogin = useRunActionTriggersAfterLogin();

    const [userFields, setUserFields] = useState<UserLoginRequest>({
        identity: {
            login: '',
        },
        password: '',
    });

    const onSubmit = useCallback(
        async (data: UserLoginRequest) => {
            const identity = {} as UserIdentity;

            if (data.identity.login.includes('@')) {
                identity.email = data.identity.login;
            } else {
                identity.login = data.identity.login;
            }

            userLogin({
                identity,
                password: data.password,
            });
        },
        [userLogin]
    );

    const onBeforeSubmit = useCallback(
        (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            if (!isFormDirty) {
                setValue('identity.login', userFields.identity.login);
                setValue('password', userFields.password);
            }

            handleSubmit(onSubmit)();
        },
        [handleSubmit, isFormDirty, onSubmit, setValue, userFields.password, userFields.identity.login]
    );

    useEffect(() => {
        if (userLoggedIn) {
            router.push(RouteE.MARKETPLACE);
            runActionTriggersAfterLogin();
        }
    }, [userLoggedIn]);

    useEffect(
        () => () => {
            clearError();
        },
        [clearError]
    );

    const onFormFieldChange = useCallback(
        ({ target }: React.ChangeEvent<HTMLInputElement>) => {
            setUserFields((prevState) => ({ ...prevState, [target.name]: target.value }));

            clearError();
        },
        [clearError]
    );

    return (
        <form data-test-id="auth-modal" onSubmit={onBeforeSubmit}>
            <StyledAuthFormContainer flexDirection="column">
                <StyledAuthFormTitle>
                    <Typography variant="title" size="lg" tag="h2" color="text" medium>
                        {locale.title}
                    </Typography>
                    <Typography variant="text" size="xs" tag="span">
                        {locale.subtitle}
                    </Typography>
                </StyledAuthFormTitle>
                <StyledAuthFieldsContainer flexDirection="column">
                    <FormTextInput
                        {...register('identity.login', {
                            ...isRequired(),
                            value: userFields.identity.login,
                            onChange: onFormFieldChange,
                        })}
                        error={errors.identity?.login?.message}
                        placeholder={locale.form.login}
                        autoComplete="on"
                        autoFocus
                    />
                    <PasswordInput
                        {...register('password', {
                            ...isRequired(),
                            value: userFields.password,
                            onChange: onFormFieldChange,
                        })}
                        error={errors?.password?.message}
                        placeholder={locale.form.password}
                        autoComplete="on"
                    />
                </StyledAuthFieldsContainer>
                {userError && (
                    <StyledFormError variant="text" size="sm" color="pink500" tag="div">
                        {userError}
                    </StyledFormError>
                )}
                <Button
                    type="submit"
                    data-test-id={`${componentTestId}-btn-submit`}
                    disabled={userLogginIn}
                    size={screen.xs || screen.sm ? 'md' : 'lg'}
                    stretch
                >
                    {locale.form.submit}
                </Button>
                <Typography variant="text" size="xs">
                    <StyledAuthTrigger onClick={() => onSwitch('Reset Password')}>
                        {locale.forgotUsername}
                    </StyledAuthTrigger>
                </Typography>
                <Typography variant="text" size="xs">
                    <Trans t={t} i18nKey="auth.login.signUp">
                        <StyledAuthTrigger onClick={() => onSwitch('Registration')} />
                    </Trans>
                </Typography>
            </StyledAuthFormContainer>
        </form>
    );
};

export { LoginModal };