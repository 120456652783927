import { FlexBox } from 'src/ui/common/atoms/flex-box';
import { Visible } from 'src/ui/common/layouts/visible';
import styled from 'styled-components';

export const StyledModalContent = styled(FlexBox)`
    height: 100%;
`;

export const StyledLogoContainer = styled(Visible)`
    flex: 1;
`;

export const StyledFormWrapper = styled(FlexBox)`
    height: 100vh;
    padding: 0 20px;
`;

export const StyledFormContainer = styled.div`
    max-width: 440px;
    width: 100%;
    margin: 0 auto;
`;