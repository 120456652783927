/**
 * Libs
 */
import styled from 'styled-components';

/**
 * Components
 */
import { FlexBox } from 'src/ui/common/atoms/flex-box';
import { Grid } from 'src/ui/common/atoms/grid';
import { Visible } from 'src/ui/common/layouts/visible';
import { Typography } from 'src/ui/common/atoms/typography';

/**
 * Utils
 */
import { media } from 'src/ui/common/mixins/media';
import { SvgIcon } from 'src/ui/common/atoms/svg-icon';

export const StyledAuthContent = styled(FlexBox)`
  height: 100%;
  flex: 1;

  & > div:nth-child(3) {
    max-width: 335px;
    margin: 0 auto;

    ${media.md`
      max-width: 440px;
    `}
    
    & > div {
      margin-top: 0;

      ${media.sm`
        margin-top: auto;
      `}
    }

    & > p {
      margin-top: auto;
      padding-bottom: 30px;
    }
  }

  & > div:last-child p a {
    color: ${({ theme }) => theme.colors.gs200};
  }

  ${media.sm`
    flex-direction: row !important;
  `}
`;

export const StyledAuthHeader = styled(FlexBox)`
  padding: 20px;

  ${media.sm`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 20px;
    z-index: 99;
  `}
  
  button {
    cursor: pointer;
    appearance: none;
    border: none;
    background: none;
    display: grid;
    place-items: center;
  }
`;

export const StyledPreviewContainer = styled(Visible)`
  position: relative;
  min-width: 35vw;

  & > div:first-child {
    position: absolute;
    height: 100%;
    width: 100%;
  }

  p {
    position: absolute;
    z-index: 99;
    left: 36px;
    bottom: 48px;
    color: ${({ theme }) => theme.colors.text};

    a {
      text-decoration: underline;
    }
  }
`;

export const StyledAuthFormContainer = styled(FlexBox)`
  input {
    width: 100%;
  }

  & > button {
    margin: 12px 0 30px;
  }

  button + span {
    display: block;
    margin: 0 0 10px;
  }

  a {
    color: ${({ theme }) => theme.colors.pink500};
  }

  & > p {
    color: ${({ theme }) => theme.colors.gs200};
    margin: 0 0 40px;
  }
`;

export const StyledAuthFieldsContainer = styled(FlexBox)`
  gap: 12px;
  
`;

export const StyledAuthFormTitle = styled.div`
  margin: 0 0 30px;
  
  & > h2 {
    margin-bottom: 10px;
  }
`;

export const StyledPasswordRequirements = styled(Grid)`
  margin: 22px 0;
`;

export const StyledAuthTrigger = styled.span`
  appearance: none;
  border: none;
  background: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.pink500};
  font: inherit;
`;

export const StyledFormError = styled(Typography)`
  margin: 22px 0 10px;
`;

export const StyledValidationIcon = styled(SvgIcon)`
  align-self: flex-start;
`;