/**
 * Libs
 */

/**
 * Components
 */
import { FlexBox } from 'src/ui/common/atoms/flex-box';
import { Grid } from 'src/ui/common/atoms/grid';
import { SvgIcon } from 'src/ui/common/atoms/svg-icon';
import { Typography } from 'src/ui/common/atoms/typography';

/**
 * Utils
 */
import styled from 'styled-components';


export const StyledAuthContent = styled(FlexBox)`
    height: 100vh;
`;

export const StyledAuthHeader = styled(FlexBox)`
    height: 90px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gs400};
`;

export const StyledLogo = styled(FlexBox)`
    width: 92px;
    height: 100%;
    user-select: none;

    img {
        padding: 4px;
        border-right: 1px solid ${({ theme }) => theme.colors.gs400};
    }
`;

export const StyledFormWrapper = styled(FlexBox)`
    height: 100%;
`;

export const StyledFormContainer = styled(FlexBox)`
    max-width: 478px;
    margin: 0 auto;
    padding: 0 20px;
`;

export const StyledAuthFormContainer = styled(FlexBox)`
    input {
        width: 100%;
    }

    & > button {
        margin: 12px 0 30px;
    }

    button + span {
        display: block;
        margin: 0 0 10px;
    }

    a {
        color: ${({ theme }) => theme.colors.pink500};
    }

    & > p {
        color: ${({ theme }) => theme.colors.gs200};
        margin: 0 0 40px;
    }
`;

export const StyledAuthFieldsContainer = styled(FlexBox)`
    gap: 12px;
`;

export const StyledAuthFormTitle = styled.div`
    margin: 0 0 30px;

    & > h2 {
        margin-bottom: 10px;
    }
`;

export const StyledPasswordRequirements = styled(Grid)`
    margin: 22px 0;
`;

export const StyledAuthTrigger = styled.span`
    appearance: none;
    border: none;
    background: none;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.pink500};
    font: inherit;
`;

export const StyledFormError = styled(Typography)`
    margin: 22px 0 10px;
`;

export const StyledValidationIcon = styled(SvgIcon)`
    align-self: flex-start;
`;

export const StyledTermsLink = styled(Typography)`
    a {
        color: ${({ theme }) => theme.colors.white} !important;
        text-decoration: underline;
    }
`;