/* eslint-disable jsx-a11y/anchor-has-content */

/* eslint-disable jsx-a11y/control-has-associated-label */

/* eslint-disable jsx-a11y/anchor-is-valid */

/**
 * Libs
 */
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

/**
 * Types
 */
import { RouteE } from 'src/constants/pages.constants';

/**
 * Hooks
 */
import { useUniverse } from 'src/features/common/universe/hooks/use-universe';
import { AuthModalProps } from 'src/features/common/user/modals/auth-modal/auth-modal.types';
import { ResetPasswordModal } from 'src/features/common/user/modals/auth-modal/templates/reset-password-modal';
import { LoginModal } from 'src/features/white-labels/thesportmuseum/user/modals/auth-modal/templates/login-modal';
import { RegistrationModal } from 'src/features/white-labels/thesportmuseum/user/modals/auth-modal/templates/registration-modal';
import { FlexBox } from 'src/ui/common/atoms/flex-box';
import { Spacing } from 'src/ui/common/atoms/spacing';
import { TextLink } from 'src/ui/common/atoms/text-link';
import { Typography } from 'src/ui/common/atoms/typography';

/**
 * Components
 */
import { Modal } from 'src/ui/common/layouts/modal';
import { getUrlWithPublicStorage } from 'src/utils/get-url-with-public-host';

/**
 * Utils
 */

/**
 * Styles
 */
import { StyledAuthContent, StyledAuthHeader, StyledFormContainer, StyledFormWrapper, StyledLogo } from './auth-modal.styles';


const componentTestId = 'auth-modal';

export const AuthModalComponent = ({ type: typeInitial }: AuthModalProps) => {
    const { t } = useTranslation(['modals']);
    const { projectName, logo } = useUniverse();
    const [type, setType] = useState(typeInitial);

    return (
        <Modal testId={componentTestId} fullScreen>
            <StyledAuthContent flexDirection="column" justifyContent="space-between">
                <StyledAuthHeader>
                    <StyledLogo>
                        <img src={getUrlWithPublicStorage(logo)} alt="" />
                    </StyledLogo>
                </StyledAuthHeader>
                <StyledFormWrapper flexDirection="column" alignItems="center" justifyContent="center">
                    <StyledFormContainer flexDirection="column">
                        <FlexBox flexDirection="column">
                            {type === 'Login' && <LoginModal onSwitch={setType} />}
                            {type === 'Registration' && <RegistrationModal onSwitch={setType} />}
                            {type === 'Reset Password' && <ResetPasswordModal onSwitch={setType} />}
                        </FlexBox>
                    </StyledFormContainer>
                </StyledFormWrapper>
                <StyledFormContainer flexDirection="column">
                    <Spacing mb="48px">
                        <Typography tag="p" variant="text" size="xs" color="gs300">
                            <Trans
                                t={t}
                                i18nKey="auth.legal"
                                values={{ projectName }}
                                components={{
                                    linkOne: <TextLink href={RouteE.TERMS} />,
                                    linkTwo: <TextLink href={RouteE.PRIVACY} />,
                                }}
                            />
                        </Typography>
                    </Spacing>
                </StyledFormContainer>
            </StyledAuthContent>
        </Modal>
    );
};