/**
 * Libs
 */
import Link from 'next/link';
import React from 'react';

export const TextLink: React.FC<{ href: string, newTab?: boolean }> = ({ newTab, children, href }) => (
    <Link href={href} prefetch={false}>
        <a target={newTab ? '_blank' : undefined}>
            {children}
        </a>
    </Link>
);
