// eslint-disable-next-line no-restricted-imports
import Image from 'next/image';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { RouteE } from 'src/constants/pages.constants';
import { useUniverse } from 'src/features/common/universe/hooks/use-universe';
import { AuthModalProps } from 'src/features/common/user/modals/auth-modal/auth-modal.types';
import { RegistrationModal } from 'src/features/common/user/modals/auth-modal/templates/registration-modal';
import { ResetPasswordModal } from 'src/features/common/user/modals/auth-modal/templates/reset-password-modal';
import {
    StyledFormContainer,
    StyledFormWrapper,
    StyledLogoContainer,
    StyledModalContent,
} from 'src/features/white-labels/syndicate/user/modals/auth-modal/auth-modal-component.styles';
import { LoginModal } from 'src/features/white-labels/syndicate/user/modals/auth-modal/templates/login-modal';
import { FlexBox } from 'src/ui/common/atoms/flex-box';
import { Spacing } from 'src/ui/common/atoms/spacing';
import { TextLink } from 'src/ui/common/atoms/text-link';
import { Typography } from 'src/ui/common/atoms/typography';
import { Modal } from 'src/ui/common/layouts/modal';

const componentTestId = 'auth-modal';

export const AuthModalComponent = ({ type: typeInitial }: AuthModalProps) => {
    const { t } = useTranslation(['modals']);
    const { projectName } = useUniverse();
    const [type, setType] = useState(typeInitial);

    return (
        <Modal testId={componentTestId} fullScreen>
            <StyledModalContent alignItems="center">
                <StyledLogoContainer view={['desktop', 'laptop']}>
                    <FlexBox justifyContent="center">
                        <Image src="/whitelabel/syndicate/syndicate-logo.svg" height={326} width={290} />
                    </FlexBox>
                </StyledLogoContainer>
                <StyledFormWrapper flexDirection="column" flex="2" justifyContent="center">
                    <StyledFormContainer>
                        <FlexBox flexDirection="column">
                            {type === 'Login' && <LoginModal onSwitch={setType} />}
                            {type === 'Registration' && <RegistrationModal onSwitch={setType} />}
                            {type === 'Reset Password' && <ResetPasswordModal onSwitch={setType} />}
                        </FlexBox>
                        <Spacing mt="40px">
                            <Typography tag="p" variant="text" size="xxs" color="gs300">
                                <Trans
                                    t={t}
                                    i18nKey="auth.legal"
                                    values={{ projectName }}
                                    components={{
                                        linkOne: <TextLink href={RouteE.TERMS} />,
                                        linkTwo: <TextLink href={RouteE.PRIVACY} />,
                                    }}
                                />
                            </Typography>
                        </Spacing>
                    </StyledFormContainer>
                </StyledFormWrapper>
            </StyledModalContent>
        </Modal>
    );
};